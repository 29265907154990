import React from 'react'
import './WebDevelopmentServices.css'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Roll from 'react-reveal/Roll';



export default function WebDevelopmentServices({ webDevelopmentServices, themeColor, ...props }) {
  return (
    <section>
      <div className="container">
        <div>
          <Fade bottom duration={1000}>
            <h2 className="text-center main-heading-title mb-0">{webDevelopmentServices.title}</h2>
            <div className="d-flex justify-content-center">
              <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
            </div>
          </Fade>
          <Fade bottom duration={2000}>
            <p className="text-center web-service-heading-description">{webDevelopmentServices?.description?.description}</p>
          </Fade>
        </div>
        <div className="row web-service-card">
          {webDevelopmentServices.ServiceType.map((p, i) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 tilt-in-fwd-tr">
              <div className="card">
                <div className="card-body">
                  <Slide top delay={i * 150} >
                    <h2 className="card-title">{p.number}</h2>
                  </Slide>
                  <Zoom top delay={i * 150}>
                    <h3 className="card-sub-title">{p.title}</h3>
                  </Zoom>
                  <Fade top delay={i * 150}>
                    <p className="card-description">{p.description.description}</p>
                  </Fade>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
