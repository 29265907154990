import React from 'react'
import dots from '../../images/Group 383.png'
import './TypesOfWebSolutions.css'
import img from '../../images/undraw_designer_life_w96d.png'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Rotate from 'react-reveal/Rotate'
import Zoom from 'react-reveal/Zoom'
import Bounce from 'react-reveal/Bounce'
import Slide from 'react-reveal/Slide'
import Jello from 'react-reveal/Jello'
import Swing from 'react-reveal/Swing'


export default function TypesOfWebSolutions({ webSolution, themeColor, ...props }) {
  return (
    <section className="types-of-web-solutions">
      <img src={dots} alt="dots" className="dots-img img-fluid d-none d-lg-block d-xl-block" />
      <img src={dots} alt="dots" className="dots-img-second img-fluid d-none d-lg-block d-xl-block" />
      <div className="container">
        <div>
          <Fade bottom duration={1000}>
            <h2 className="text-center web-title mb-0 main-heading-title">{webSolution.title}</h2>
            <div className="d-flex justify-content-center">
              <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
            </div>
          </Fade>
          <Fade bottom duration={2000}>
            <p className="text-center web-description">{webSolution?.description?.description}</p>
          </Fade>
        </div>
        <div className="row web-type-contain">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center align-items-center">
            <img src={img} className="web-solution-img" alt="img-fluid" />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h2 className="web-types-heading">{webSolution.subTitle}</h2>
            <p className="web-types-heading-description">{webSolution?.subDescription?.subDescription}</p>
            <div className="row ">
              {webSolution.ServiceType.map((p, i) => {
                return (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                      <div className="col-4 d-flex justify-content-center">
                        <Zoom delay={i * 200} duration={2000}>
                          <div className="round-shape d-flex justify-content-center align-items-center" style={{ background: p.backgroundColor }}>
                            <h2 className="mb-0 round-shape-number">{p.number}</h2>
                          </div>
                        </Zoom>
                      </div>
                      <div className="col-8 px-lg-0 px-xl-0">
                        <Slide delay={i * 150} right={i % 2 !== 0} left={i % 2 === 0}>
                          <h1 className="web-types-title mb-2">{p.title}</h1>
                        </Slide>
                        <Slide delay={i * 150} right={i % 2 !== 0} left={i % 2 === 0}>
                          <p className="web-types-description">{p.description.description}</p>
                        </Slide>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div >
    </section >
  )
}
