import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Navbar from "../components/Dashboard/Navbar/Navbar"
import Header from "../components/Dashboard/Header/Header"
import CustomHeader from "../components/CustomHeader/Header"
import './style.css';
import Service from "../components/Service/Service"
import ProjectCount from "../components/ProjectCount/ProjectCount"
import OurProcess from "../components/OurProcess/OurProcess"
import Quote from "../components/Quote/Quote"
import OurRecentWork from "../components/OurRecentWork/OurRecentWork"
import Feedback from "../components/Feedback/Feedback"
import Footer from "../components/Dashboard/Footer/Footer"
import Copyrights from "../components/Copyrights/Copyrights"
import TypesOfWebSolutions from "../components/TypesOfWebSolutions/TypesOfWebSolutions"
import WebDevelopmentServices from "../components/WebDevelopmentServices/WebDevelopmentServices"
import ContactUs from "../components/ContactUs/ContactUs"
import LessIcon from '../images/expand_less_black_24dp.svg'


export const query = graphql`
    query Query {
   webDevelopment: contentfulWebDevlopment(slug: {eq: "/web-development"}) {
    id
    themeColor
    navbars {
      title
      to
      hRef
    }
     header {
      title
      description {
        description
      }
      actionImage {
        gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE ,  width:50 )
      }
      image {
         gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
      }
    }
    service {
      title
      subTitle
      ServiceType {
        title
        description {
          description
        }
        number
        backgroundColor
      }
    }
    otherServices {
      title
     
      ServiceType {
        number
        title
        description {
          description
        }
      }
    }
    designAndDevelop {
      title
      description {
        description
      }
      processType {
        title
        description {
          description
        }
        heroImage {
           gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    footer {
      logo {
         gatsbyImageData(layout: FULL_WIDTH)
      }
      email
      phoneNumber
      footerHeading {
        title
        footerSection {
          slug
          text
        }
      }
      socialLink {
        icon {
           gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    footerWaveColor {
      firstWaveColor
      secondWaveColor
    }
    copyright {
      title
    }
  }
}
    `

const WebDevelopment = ({ location, data: { webDevelopment } }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function scrollHandler() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  return (
    <>
      <SEO title="Home" />
      <Navbar navbar={webDevelopment.navbars} themeColor={webDevelopment.themeColor} />
      {/* <Header header={webDevelopment.header} /> */}
      {/* <CustomHeader header={webDevelopment.header.reduce((p, c) => ({ ...p, ...c }), {})} /> */}
      <Header header={webDevelopment.header.reduce((p, c) => ({ ...p, ...c }), {})} />
      <TypesOfWebSolutions webSolution={webDevelopment.service.reduce((p, c) => ({ ...p, ...c }), {})} themeColor={webDevelopment.themeColor} />
      <WebDevelopmentServices webDevelopmentServices={webDevelopment.otherServices.reduce((p, c) => ({ ...p, ...c }), {})} themeColor={webDevelopment.themeColor} />
      <OurProcess process={webDevelopment.designAndDevelop} themeColor={webDevelopment.themeColor} />
      {/* <ContactUs /> */}
      {/* <Service service={homePage.Service} />
      <ProjectCount projectCount={homePage.projectCount} />
      <Quote quote={homePage.quote} />
      <OurRecentWork recentWork={homePage.recentWork} />
      <Feedback feedback={homePage.feedback} /> */}
      <Footer footer={webDevelopment.footer} copyright={webDevelopment.copyright} />
      {/* <Footer footer={webDevelopment.footer} copyright={webDevelopment.copyright} footerWaveColor={webDevelopment.footerWaveColor} /> */}
      <div className="scroll-button-circle" onClick={scrollHandler} style={{ display: scrollPosition > 1000 ? 'flex' : 'none', background: webDevelopment.themeColor }}>
        <img src={LessIcon} alt="lessIcon" className="mb-0 less-icon" />
      </div>
    </>
  )
}

export default WebDevelopment
